import { Params } from '@angular/router';
import { SafeAny } from 'sfx-commons';
import { LOGIN_QUERY } from '../../constants';

export class LocationUtils {
    private static loginLogoutQueryParamKeys = Object.values(LOGIN_QUERY).map(value => value.toString());

    public static parseQueryString(uri?: string): { queryParams: Params } {
        const source = uri || window.location.search;
        if (source?.length) {
            const navigationExtras: { [key: string]: string } = {};
            source.substring(1).split('&').map(item => {
                const keyValue = item.split('=');
                navigationExtras[keyValue[0]] = keyValue[1];
            });

            return { queryParams: navigationExtras };
        }

        return null;
    }

    public static parseQueryStringFromUri(uri: string): { queryParams: Params } {
        const index = uri.indexOf('?');
        if (index > -1) {
            return LocationUtils.parseQueryString(uri.substring(index, uri.length));
        }

        return null;
    }

    public static extractLoginAndLogoutRelatedQueryParams(queryParams: Params): Params {
        return this.getFilteredParams(queryParams, (key) => this.loginLogoutQueryParamKeys.includes(key));
    }

    public static extractNonLoginAndLogoutRelatedQueryParams(queryParams: Params): Params {
        return this.getFilteredParams(queryParams, (key) => !this.loginLogoutQueryParamKeys.includes(key));
    }

    private static getFilteredParams(params: Params, predicate: (paramKey: string, paramValue: SafeAny) => boolean): Params {
        if (!params || !predicate) {
            return null;
        }

        return Object.keys(params)
            .filter(key => predicate(key, params[key]))
            .reduce((result: Params, key: string) => ({
                ...result,
                [key]: params[key]
            }), {});
    }
}