import { AuthConfig } from 'angular-oauth2-oidc';
import { isNullOrUndefined } from 'sfx-commons';
import { environment } from '../../../environments/environment';
import { LegacyIdentityProvider, IdentityProvider } from '../../constants';
import { IdentityProviderRefreshTokenConfigMap } from '../../constants/identity-provider-refresh-token-config-map';
import { ObjectUtils } from './object.utils';

const clockSkewInSec = 60;

export function getDefaultSSOConfigs(): { [key in IdentityProvider]?: AuthConfig } {
    return {
        [IdentityProvider.SwissLife]: {
            issuer: environment.slSsoUrl,
            redirectUri: `${ window.location.origin }/en/logging-in`,
            clientId: environment.slSsoClient,
            responseType: 'code',
            scope: 'openid profile swissfex.api token-exchange.myclient',
            showDebugInformation: !environment.production,
            useSilentRefresh: false,
            clockSkewInSec
        },
        [IdentityProvider.DfsIdentityServer]: {
            issuer: environment.dfsIdentityServerUrl,
            redirectUri: `${ window.location.origin }/en/logging-in`,
            clientId: environment.dfsIdentityServerClient,
            responseType: 'code',
            scope: `openid profile${ IdentityProviderRefreshTokenConfigMap[IdentityProvider.DfsIdentityServer]?.isEnabled ? ' offline_access' : '' }`,
            postLogoutRedirectUri: `${ window.location.origin }/en/login`,
            useSilentRefresh: false,
            clockSkewInSec
        }
    };
}

type SSOExtendedOptions = {
    shouldRedirectToLogout: boolean
}

export const SSOExtendedConfig: { [key in IdentityProvider]: SSOExtendedOptions } = {
    [IdentityProvider.SwissLife]: {
        shouldRedirectToLogout: false
    },
    [IdentityProvider.DfsIdentityServer]: {
        shouldRedirectToLogout: true
    }
};

export function getIdentityProvider(provider: LegacyIdentityProvider | IdentityProvider): IdentityProvider | null {
    if (isNullOrUndefined(provider)) {
        return null;
    }

    if (provider in IdentityProvider) {
        return provider as IdentityProvider;
    }

    const legacyIdentityProviderKey = ObjectUtils.getKeyForValue<keyof typeof LegacyIdentityProvider>(LegacyIdentityProvider, provider);
    if (legacyIdentityProviderKey in IdentityProvider) {
        return IdentityProvider[legacyIdentityProviderKey];
    }

    return null;
}