import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAutocompleteOption, ISelectOption } from 'sfx-commons';
import { SWISS_NATIONALITY_ALPHA2_CODE } from '../../constants';
import { ROUTE_ID } from '../../constants/api-routes';
import { INationality } from '../models/INationality';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class NationalityService {
    public cachedNationalityOptionsObservable: Observable<Array<IAutocompleteOption<number>>>;
    public swissNationalityObservable: Observable<INationality>;

    private cachedNationalityOptionsSubject: BehaviorSubject<Array<IAutocompleteOption<number>>>;
    private swissNationalitySubject: BehaviorSubject<INationality>;

    constructor(private readonly httpSvc: HttpService) {
        this.swissNationalitySubject = new BehaviorSubject<INationality>(undefined);
        this.cachedNationalityOptionsSubject = new BehaviorSubject<Array<ISelectOption<number>>>([]);
        this.swissNationalityObservable = this.swissNationalitySubject.asObservable();
        this.cachedNationalityOptionsObservable = this.cachedNationalityOptionsSubject.asObservable();
    }

    public async getNationalities(): Promise<void> {
        const nationalities = await this.httpSvc
            .get<Array<INationality>>(null, ROUTE_ID.GET_NATIONALITIES);

        const options: Array<IAutocompleteOption<number>> = [];
        nationalities.forEach(nationality => {
            options.push({
                value: nationality.id,
                label: nationality.name
            });

            if (nationality.nationalityAlpha2Code === SWISS_NATIONALITY_ALPHA2_CODE) {
                this.swissNationalitySubject.next(nationality);
            }
        });

        this.cachedNationalityOptionsSubject.next(options);
    }
}
