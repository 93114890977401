import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { filter } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppInitializerService } from '../services/app-initializer.service';

@Injectable({ providedIn: 'root' })
class IsAppInitializedGuardService {
    constructor(private readonly appInitializerSvc: AppInitializerService) {}

    public canActivate(): Promise<boolean> {
        return new Promise<boolean>(resolve => {
            const loadedRoutes = this.appInitializerSvc.getLoadedRoutes();

            if (loadedRoutes) {
                return resolve(true);
            } else {
                this.appInitializerSvc.areRoutesLoaded
                    .pipe(
                        filter(routes => !!routes),
                        take(1)
                    )
                    .subscribe(routes => {
                        if (routes) {
                            return resolve(true);
                        }
                    });
            }
        });
    }
}


export const isAppInitializedGuard: CanActivateFn = () => {
    return inject(IsAppInitializedGuardService).canActivate();
};
