import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableHeightAdjusterDirective } from './directives/available-height-adjuster.directive';
import { ClearInputWarnerDirective } from './directives/clearInputWarner.directive';
import { ConfirmLeaveBeforeClickDirective } from './directives/confirm-leave-before-click.directive';
import { CustomInputDirective } from './directives/customInput.directive';
import { MinHeightAdjusterDirective } from './directives/min-height-adjuster.directive';
import { NumberDirective } from './directives/number.directive';
import { PositiveNumberDirective } from './directives/positiveNumber.directive';
import { ScrollToActiveStatusDirective } from './directives/scroll-to-active-status.directive';
import { ScrollToBottomDirective } from './directives/scroll-to-bottom.directive';
import { ScrollToCollapsibleDocumentGroupDirective } from './directives/scroll-to-collapsible-document-group.directive';
import { ScrollToDocumentGroupDirective } from './directives/scroll-to-document-group.directive';
import { SetHeightOfBodyToCurrentElementDirective } from './directives/set-height-of-body-to-current-element.directive';
import {
    SetHeightOfOffersPageVerticalLineDirective
} from './directives/set-height-of-offers-page-vertical-line.directive';
import { SetUpOfferListFooterPositioningDirective } from './directives/set-up-offer-list-footer-positioning.directive';

import { CapitalizeFirstWordPipe } from './pipes/capitalize-first-word.pipe';
import { IsDateBeforePipe } from './pipes/is-date-before.pipe';
import { MathAbsPipe } from './pipes/math-abs.pipe';
import { NoNullsPipe } from './pipes/no-nulls.pipe';
import { OnlyHavingTruthyPropertyPipe } from './pipes/only-having-truthy-property.pipe';
import { RemoveFileExtensionPipe } from './pipes/remove-file-extension.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { BypassSafetyPipe } from './pipes/bypass-safety.pipe';
import { ExtractEndingParenthesesPipe } from './pipes/extract-ending-parentheses.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TimePipe } from './pipes/time.pipe';
import { ToStringPipe } from './pipes/to-string.pipe';
import { TranslateOutletPipe } from './pipes/translate-outlet.pipe';
import { TranslateRoutePipe } from './pipes/translate-route.pipe';
import { ByteToMegabytePipe } from './pipes/byte-to-megabyte.pipe';
import { SelectedDropdownItemPipe } from './pipes/selected-dropdown-item.pipe';
import { AddressPipe } from './pipes/address.pipe';
import { PrivateTextPipe } from './pipes/private-text.pipe';
@NgModule({
    declarations: [
        AddressPipe,
        AvailableHeightAdjusterDirective,
        BypassSafetyPipe,
        ByteToMegabytePipe,
        CapitalizeFirstWordPipe,
        ClearInputWarnerDirective,
        ConfirmLeaveBeforeClickDirective,
        CustomInputDirective,
        ExtractEndingParenthesesPipe,
        IsDateBeforePipe,
        MathAbsPipe,
        MinHeightAdjusterDirective,
        NoNullsPipe,
        NumberDirective,
        OnlyHavingTruthyPropertyPipe,
        PositiveNumberDirective,
        PrivateTextPipe,
        RemoveFileExtensionPipe,
        ScrollToActiveStatusDirective,
        ScrollToBottomDirective,
        ScrollToCollapsibleDocumentGroupDirective,
        ScrollToDocumentGroupDirective,
        SearchPipe,
        SelectedDropdownItemPipe,
        SetHeightOfBodyToCurrentElementDirective,
        SetHeightOfOffersPageVerticalLineDirective,
        SetUpOfferListFooterPositioningDirective,
        TimeAgoPipe,
        TimePipe,
        ToStringPipe,
        TranslateOutletPipe,
        TranslateRoutePipe
    ],
    exports: [
        AddressPipe,
        AvailableHeightAdjusterDirective,
        BypassSafetyPipe,
        ByteToMegabytePipe,
        CapitalizeFirstWordPipe,
        ClearInputWarnerDirective,
        ConfirmLeaveBeforeClickDirective,
        CustomInputDirective,
        ExtractEndingParenthesesPipe,
        IsDateBeforePipe,
        MathAbsPipe,
        MinHeightAdjusterDirective,
        NoNullsPipe,
        NumberDirective,
        OnlyHavingTruthyPropertyPipe,
        PositiveNumberDirective,
        PrivateTextPipe,
        RemoveFileExtensionPipe,
        ScrollToActiveStatusDirective,
        ScrollToBottomDirective,
        ScrollToCollapsibleDocumentGroupDirective,
        ScrollToDocumentGroupDirective,
        SearchPipe,
        SelectedDropdownItemPipe,
        SetHeightOfBodyToCurrentElementDirective,
        SetHeightOfOffersPageVerticalLineDirective,
        SetUpOfferListFooterPositioningDirective,
        TimeAgoPipe,
        TimePipe,
        ToStringPipe,
        TranslateOutletPipe,
        TranslateRoutePipe
    ],
    imports: [
        CommonModule
    ]
})
export class CommonUtilsModule {
}
