import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Params } from '@angular/router';
import { takeLast, takeWhile } from 'rxjs';
import { isNullOrUndefined } from 'sfx-commons';
import { environment } from '../../../environments/environment';
import { LOGIN_QUERY, LOGIN_QUERY_LOGOUT_REASON_MAP, LOGOUT_REASON } from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { IApplicationUser } from '../models/IApplicationUser';
import { RouterService } from '../services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../services/session-storage.service';
import { TranslateRouterService } from '../services/translate-router.service';

@Injectable({ providedIn: 'root' })
class AuthenticatedGuardService {
    constructor(private readonly authSvc: AuthenticationService,
                private readonly madTranslateSvc: MadTranslateService,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly translateRouterSvc: TranslateRouterService) {}

    public canActivate(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.authSvc.authStatus
                .pipe(
                    takeWhile(authData => !authData, true),
                    takeLast(1)
                )
                .subscribe(async (authData) => {
                    const queryParams: Params = {
                        [LOGIN_QUERY.RETURN_URL]: this.routerSvc.getValidReturnUrl(`${ location.pathname }${ location.search }`)
                    };

                    try {
                        if (authData.isAuthenticated) {
                            const currentUser = await this.authSvc.getCurrentUser();

                            if (currentUser) {
                                if (currentUser.userType) {
                                    let location = window.location.toString();
                                    const host = window.location.host;
                                    if (host !== environment.originsPerUserType[currentUser.userType]) {
                                        location = location.replace(host,
                                            environment.originsPerUserType[currentUser.userType]);
                                        window.location.href = location;
                                        return;
                                    }
                                }

                                await this.handleAuthenticatedUserLanguageChanges(currentUser);
                            }
                            resolve(true);
                        } else {
                            if (queryParams[LOGIN_QUERY.RETURN_URL]) {
                                this.sessionStorageSvc.set(SESSION_STORAGE_KEY.RETURN_URL_KEY, queryParams[LOGIN_QUERY.RETURN_URL]);
                            }

                            await this.authSvc.login(this.authSvc.currentIdentityProvider);
                            resolve(false);
                        }
                    } catch (ex) {
                        console.log(ex);
                        if (ex instanceof HttpErrorResponse) {
                            if (!isNullOrUndefined(ex.status)) {
                                const loginQuery = LOGIN_QUERY_LOGOUT_REASON_MAP[ex.status.toString()] || LOGIN_QUERY.ERROR;
                                queryParams[loginQuery] = ex.status;
                            } else {
                                const reason = LOGOUT_REASON.FORBIDDEN;
                                queryParams[LOGIN_QUERY_LOGOUT_REASON_MAP[reason]] = reason;
                            }
                        }
                        await this.routerSvc.navigateToLogout({ queryParams });
                        resolve(false);
                    }
                });
        });
    }

    private async handleAuthenticatedUserLanguageChanges(currentUser: IApplicationUser): Promise<void> {
        const language = this.madTranslateSvc.getAppropriateUiLanguage(currentUser);
        const urlLanguage = this.madTranslateSvc.getSelectedLanguage();
        if (language !== urlLanguage) {
            const pathName = await this.translateRouterSvc.parseAndTranslateUrl(language, location.pathname);
            this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
            window.location.pathname = pathName;
            return;
        } else {
            this.madTranslateSvc.setNonRouterInvasiveServicesLanguage(language);
        }
    }
}


export const authenticatedGuard: CanActivateFn = () => {
    return inject(AuthenticatedGuardService).canActivate();
};
