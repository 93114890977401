import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    faArrowRightFromBracket as falArrowRightFromBracket,
    faBell as falBell,
    faFileLines as falFileLines,
    faGear as falGear,
    faHouseChimney as falHouseChimney,
    faMessagePen as falMessagePen,
    faNoteSticky as falNoteSticky,
    faUserGear as falUserGear,
    faUserHeadset as falUserHeadset,
    faUsers as falUsers,
    faUserSecret as falUserSecret
} from '@fortawesome/pro-light-svg-icons';
import { faUserSecret as fasUserSecret } from '@fortawesome/pro-solid-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Subscription } from 'rxjs';
import { tryToUnsubscribeFromSubscription } from 'sfx-commons';
import { Userpilot } from 'userpilot';
import { environment } from '../../../environments/environment';
import {
    EDIT_PROJECT_PATHS,
    LOGIN_QUERY_LOGOUT_REASON_MAP,
    LOGOUT_REASON,
    MAIN_ROUTES,
    OUTLET_NAMES,
    PATH_NAMES_PER_OUTLETS,
    PROJECT_PAGE_URL_SUBITEMS_TEMPLATE
} from '../../constants';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { RealTimeNotificationService } from '../../core/services/real-time-notification.service';
import { RouterService } from '../../core/services/router.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../../core/services/session-storage.service';
import { DocumentUtils } from '../../core/utils/document.utils';
import { ProjectUtils } from '../../core/utils/project.utils';
import { RouterUtils } from '../../core/utils/router.utils';
import { isUserPilotFlowEnabled } from '../../core/utils/utils';
import { ProjectStatus } from '../../projects/models/enums/projectStatus';
import { INews } from '../../projects/models/INews';
import { IPrivacySettings } from '../../projects/models/IPrivacySettings';
import { IProject } from '../../projects/models/IProject';
import { AuthenticationService } from '../../projects/services/authentication.service';
import { AuthorizationService } from '../../projects/services/authorization.service';
import { DocumentsService } from '../../projects/services/documents.service';
import { NewsService } from '../../projects/services/news.service';
import { PrivacyService } from '../../projects/services/privacy.service';
import { ProjectsService } from '../../projects/services/projects.service';
import { ISidebarItem, SidebarItemHighlightType } from '../sidebar-item/sidebar-item.component';

const COMPACT_TRIGGER_WIDTH = 1680;

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-navigation.component.html',
    styleUrls: ['./sidebar-navigation.component.scss']
})
export class SidebarNavigationComponent implements OnChanges, OnInit {
    @Input()
    public urlProjectId: number;

    private readonly navItemStaticKeys = {
        description: 'navigation_title.description',
        documentsModal: 'navigation_title.documents',
        documentsPage: 'edit_project.documents',
        feedback: 'navigation_title.feedback',
        home: 'navigation_title.home',
        logout: 'navigation_title.logout',
        news: 'navigation_title.news',
        privacyOff: 'navigation_title.privacy_mode_off',
        privacyOn: 'navigation_title.privacy_mode_on',
        serviceCenter: 'navigation_title.service_center',
        settings: 'navigation_title.settings',
        teamSelling: 'navigation_title.team_selling'
    };
    public readonly HOME_NAV_ITEM: ISidebarItem = {
        key: this.navItemStaticKeys.home,
        icon: falHouseChimney,
        routeSubItems: [MAIN_ROUTES.PROJECTS],
        routerLinkActive: "mad--selected mad--disabled mad-navigation-bar-item--disabled",
        routerLinkActiveOptions: { queryParams: 'ignored', paths: 'exact', matrixParams: 'ignored', fragment: 'ignored' },
        isVisible: true
    };
    public isCompact = false;
    public PROJECT_SPECIFIC_NAV_ITEMS: Array<ISidebarItem> = this.projectSpecificNavItems;

    private readonly userProfileItem: ISidebarItem = {
        routeSubItems: ['profile'],
        key: '',
        extraClasses: 'nav-max-width',
        icon: falUserGear,
        outstandingNotifications: 0,
        isDisabled: true,
        isVisible: true,
        hideCollapsed: true
    };

    private get documentsModalNavItem(): ISidebarItem {
        return {
            key: this.navItemStaticKeys.documentsModal,
            icon: falFileLines,
            outstandingNotifications: 0,
            routeSubItems: PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
            routeSubItemParams: this.project?.id ? [this.project.id] : null,
            isVisible: false,
            outlet: { [OUTLET_NAMES.DOCUMENT_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.DOCUMENT_MODAL_OUTLET] }
        };
    }

    private get documentsPageNavItem(): ISidebarItem {
        return {
            key: this.navItemStaticKeys.documentsPage,
            icon: falFileLines,
            outstandingNotifications: 0,
            isVisible: false,
            routeSubItems: [...PROJECT_PAGE_URL_SUBITEMS_TEMPLATE, EDIT_PROJECT_PATHS.DOCUMENTS],
            routeSubItemParams: this.project?.id ? [this.project.id] : null,
            isAbsolute: false,
            queryParamsHandling: 'preserve'
        };
    }

    private get projectSpecificNavItems(): Array<ISidebarItem> {
        return [
            {
                key: this.navItemStaticKeys.teamSelling,
                icon: falUsers,
                outstandingNotifications: 0,
                routeSubItems: PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
                routeSubItemParams: this.project?.id ? [this.project.id] : null,
                isVisible: true,
                outlet: { [OUTLET_NAMES.TEAM_SELLING_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.TEAM_SELLING_MODAL_OUTLET] },
                queryParamsHandling: 'preserve'
            },
            {
                key: this.navItemStaticKeys.description,
                icon: falNoteSticky,
                outstandingNotifications: 0,
                routeSubItems: PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
                routeSubItemParams: this.project?.id ? [this.project.id] : null,
                isVisible: true,
                outlet: { [OUTLET_NAMES.PROJECT_DESCRIPTION_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.PROJECT_DESCRIPTION_OUTLET] },
                queryParamsHandling: 'preserve'
            },
            this.documentsPageNavItem,
            this.documentsModalNavItem
        ];
    }

    public get newsItem(): ISidebarItem {
        return {
            key: this.navItemStaticKeys.news,
            icon: falBell,
            isVisible: !!this.news?.length,
            routeSubItems: [MAIN_ROUTES.PROJECTS],
            outlet: { [OUTLET_NAMES.NEWS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.NEWS_MODAL_OUTLET] },
            queryParamsHandling: 'preserve'
        };
    }

    public get bottomNavItems(): Array<ISidebarItem> {
        return [
            this.newsItem,
            {
                key: this.navItemStaticKeys.serviceCenter,
                extraClasses: 'nav-max-width',
                icon: falUserHeadset,
                isVisible: true,
                routeSubItems: [MAIN_ROUTES.PROJECTS],
                outlet: { [OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.SERVICE_CENTER_MODAL_OUTLET] },
                queryParamsHandling: 'preserve'
            }, {
                key: this.navItemStaticKeys.feedback,
                extraClasses: 'nav-max-width',
                icon: falMessagePen,
                isVisible: isUserPilotFlowEnabled(environment.features?.userPilot?.flows?.leave_feedback),
                onClick: () => Userpilot.trigger(environment.features.userPilot.flows.leave_feedback.key)
            }, {
                key: this.navItemStaticKeys.settings,
                extraClasses: 'nav-max-width',
                icon: falGear,
                isVisible: true,
                routeSubItems: [MAIN_ROUTES.PROJECTS],
                outlet: { [OUTLET_NAMES.SETTINGS_MODAL_OUTLET]: PATH_NAMES_PER_OUTLETS[OUTLET_NAMES.SETTINGS_MODAL_OUTLET] },
                queryParamsHandling: 'preserve'
            },
            this.userProfileItem
        ];
    }

    public BOTTOM_NAV_ITEMS: Array<ISidebarItem> = this.bottomNavItems;
    public readonly logoutItem: ISidebarItem = {
        key: this.navItemStaticKeys.logout,
        icon: falArrowRightFromBracket,
        isVisible: false,
        routeSubItems: [MAIN_ROUTES.LOGOUT],
        isAbsolute: true,
        queryParams: { [LOGIN_QUERY_LOGOUT_REASON_MAP[LOGOUT_REASON.USER_LOGOUT]]: LOGOUT_REASON.USER_LOGOUT }
    };
    public readonly privacyModeItem: ISidebarItem;

    public isAnonymized: boolean;
    public isBackofficeUser = false;
    public isNavigationGuardOrResolverInProgress = false;
    public currentUser: IApplicationUser;
    public news: Array<INews>;

    private project: IProject;
    private hasTeamSellingAccessPermission = false;
    private readonly webSocketConnectedClass = 'web-socket-connection-status__connected';
    private readonly webSocketDisconnectedClass = 'web-socket-connection-status__disconnected';
    private cachedProjectSubscription: Subscription;

    constructor(private readonly authenticationSvc: AuthenticationService,
                private readonly authorizationSvc: AuthorizationService,
                private readonly documentsSvc: DocumentsService,
                private readonly newsSvc: NewsService,
                private readonly privacySvc: PrivacyService,
                private readonly projectSvc: ProjectsService,
                private readonly realTimeNotificationSvc: RealTimeNotificationService,
                private readonly routerSvc: RouterService,
                private readonly sessionStorageSvc: SessionStorageService) {
        this.sessionStorageSvc.remove(SESSION_STORAGE_KEY.RETURN_URL_KEY);
        this.privacyModeItem = {
            key: this.navItemStaticKeys.privacyOff,
            icon: falUserSecret,
            isVisible: true,
            onClick: this.togglePrivacyMode
        };

        this.checkAndToggleClass(window.innerWidth);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.urlProjectId) {
            tryToUnsubscribeFromSubscription(this.cachedProjectSubscription);
            if (this.urlProjectId) {
                this.cachedProjectSubscription = this.projectSvc.cachedProjectObservable(this.urlProjectId)
                    .pipe(
                        untilDestroyed(this),
                        filter(project => !project || !!project.projectStatus),
                    )
                    .subscribe(project => this.onProjectReceived(project));
            } else {
                this.onProjectReceived(null);
            }
        }
    }

    public async ngOnInit(): Promise<void> {
        this.privacySvc.privacyObservable
            .pipe(untilDestroyed(this))
            .subscribe(({ isAnonymized }: IPrivacySettings) => {
                this.isAnonymized = isAnonymized;

                this.privacyModeItem.key = this.isAnonymized ? this.navItemStaticKeys.privacyOn : this.navItemStaticKeys.privacyOff;
                this.privacyModeItem.icon = this.isAnonymized ? fasUserSecret : falUserSecret;
                this.privacyModeItem.isInverted = this.isAnonymized;
            });

        this.authenticationSvc.authStatus
            .pipe(untilDestroyed(this))
            .subscribe(authData => {
                if (authData) {
                    this.logoutItem.isVisible = authData.isAuthenticated;
                }
            });

        this.authenticationSvc.currentUser$
            .pipe(untilDestroyed(this))
            .subscribe(currentUser => {
                this.currentUser = currentUser;
                this.isBackofficeUser = this.authenticationSvc.isSfxAdmin;

                if (this.isBackofficeUser) {
                    this.userProfileItem.hideCollapsed = false;
                }

                if (currentUser) {
                    this.userProfileItem.isVisible = true;
                    this.userProfileItem.key = `${ currentUser.firstname || '' } ${ currentUser.lastname || '' }`.trim();
                    // } else {
                    //     this.userProfileItem.isVisible = false;
                    // }
                }

                this.updateTeamSellingAvailability();
            });

        this.authorizationSvc.openedProjectPermissionMapObservable
            .pipe(untilDestroyed(this))
            .subscribe(projectPermissionMap => {
                this.hasTeamSellingAccessPermission = !!projectPermissionMap?.PP_TeamSellingReadOnlyAccess;
                this.updateTeamSellingAvailability();
            });

        this.documentsSvc.requiredDocumentsObservable
            .pipe(untilDestroyed(this))
            .subscribe(() => this.updateDocumentsAvailability());

        this.realTimeNotificationSvc.isWebSocketConnected
            .pipe(untilDestroyed(this))
            .subscribe((isConnected: boolean) => {
                if (this.isBackofficeUser) {
                    // improve, extract default class
                    this.userProfileItem.extraClasses = `nav-max-width ${ isConnected ? this.webSocketConnectedClass : this.webSocketDisconnectedClass }`;
                }
            });

        this.newsSvc.news$
            .pipe(untilDestroyed(this))
            .subscribe((news: Array<INews>) => {
                this.news = news;
                this.BOTTOM_NAV_ITEMS = this.bottomNavItems;
            });

        this.routerSvc.isNavigationGuardOrResolverInProgress$
            .pipe(untilDestroyed(this))
            .subscribe(isNavigationGuardOrResolverInProgress => this.isNavigationGuardOrResolverInProgress = isNavigationGuardOrResolverInProgress);
    }

    private updateProjectSpecificItems(): void {
        this.updateDocumentsAvailability();
        this.updateTeamSellingAvailability();
        this.updateDescriptionAvailability();
    }

    private updateTeamSellingAvailability = (): void => {
        const teamSellingIndex = this.PROJECT_SPECIFIC_NAV_ITEMS
            .findIndex(navItem => navItem.key === this.navItemStaticKeys.teamSelling);
        if (teamSellingIndex > -1) {
            this.PROJECT_SPECIFIC_NAV_ITEMS[teamSellingIndex].isVisible = RouterUtils
                .canUserAccessTeamSelling(this.currentUser, this.project?.owner?.id,
                    this.project?.owner?.tenantSettings?.isTeamSellingEnabled, this.hasTeamSellingAccessPermission);
        }
    };

    public updateDescriptionAvailability = (): void => {
        const descriptionIndex = this.PROJECT_SPECIFIC_NAV_ITEMS.findIndex(navItem => navItem.key === this.navItemStaticKeys.description);
        if (descriptionIndex > -1) {
            this.PROJECT_SPECIFIC_NAV_ITEMS[descriptionIndex].isVisible = !!this.project;
        }
    };

    public updateDocumentsAvailability(): void {
        const documentsPageSidebarItem = this.PROJECT_SPECIFIC_NAV_ITEMS?.find(navItem => navItem.key === this.navItemStaticKeys.documentsPage);
        const documentsModalSidebarItem = this.PROJECT_SPECIFIC_NAV_ITEMS?.find(navItem => navItem.key === this.navItemStaticKeys.documentsModal);
        let visibleSidebarItem: ISidebarItem;
        let hiddenSidebarItems: Array<ISidebarItem>;
        let highlightType = null;

        if (this.project) {
            highlightType = this.project.projectStatus === ProjectStatus.AdditionalDocumentsRequestedByCC
                ? SidebarItemHighlightType.Error
                : DocumentUtils.areThereAnyNewValidDocuments(this.documentsSvc) ? SidebarItemHighlightType.Warning : null;
            if (ProjectUtils.canAccessDocumentsPageForProject(this.project)) {
                visibleSidebarItem = documentsPageSidebarItem;
                hiddenSidebarItems = [documentsModalSidebarItem];
            } else {
                visibleSidebarItem = documentsModalSidebarItem;
                hiddenSidebarItems = [documentsPageSidebarItem];
            }
        } else {
            hiddenSidebarItems = [documentsPageSidebarItem, documentsModalSidebarItem];
        }

        if (!!visibleSidebarItem) {
            visibleSidebarItem.isVisible = true;
            visibleSidebarItem.highlightType = highlightType;
        }

        if (hiddenSidebarItems?.length > 0) {
            for (const hiddenSidebarItem of hiddenSidebarItems) {
                if (!!hiddenSidebarItem) {
                    hiddenSidebarItem.isVisible = false;
                    hiddenSidebarItem.highlightType = highlightType;
                }
            }
        }
    }

    public togglePrivacyMode = () => {
        this.privacySvc.setAnonymization(!this.isAnonymized);
    };

    @HostListener('window:resize', ['$event'])
    public onResize(event) {
        this.checkAndToggleClass(event.target.innerWidth);
    }

    private checkAndToggleClass(width: number) {
        this.isCompact = width <= COMPACT_TRIGGER_WIDTH;
    }

    private onProjectReceived(project: IProject): void {
        if (this.urlProjectId && !!project) {
            if (this.urlProjectId === project.id) {
                this.project = project;
                if (this.project) {
                    this.PROJECT_SPECIFIC_NAV_ITEMS = this.projectSpecificNavItems;
                    this.updateProjectSpecificItems();
                } else {
                    this.PROJECT_SPECIFIC_NAV_ITEMS = [];
                }
            } else {
                return;
            }
        } else {
            this.project = null;
            this.PROJECT_SPECIFIC_NAV_ITEMS = [];
        }
        this.BOTTOM_NAV_ITEMS = this.bottomNavItems;
    }
}
