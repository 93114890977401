import { Injectable } from '@angular/core';
import { JsonUtils } from '../utils/json.utils';
import { ObjectUtils } from '../utils/object.utils';

export enum SESSION_STORAGE_KEY {
    LANGUAGE = 'LANGUAGE',
    ADMIN_BANNER_HIDDEN = 'ADMIN_BANNER_HIDDEN',
    ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS = 'ACTIVE_OFFER_DETAILS_COLLAPSIBLE_PANEL_IDS',
    RETURN_URL_KEY = 'return_url'
}

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {
    public get<T>(key: SESSION_STORAGE_KEY): T {
        const item = sessionStorage.getItem(key);
        return JsonUtils.parseText(item) as T;
    }

    public set(key: SESSION_STORAGE_KEY, content: unknown): void {
        let valueToSet;
        if (ObjectUtils.isObjectOrArray(content)) {
            valueToSet = JSON.stringify(content);
        } else {
            valueToSet = content;
        }

        sessionStorage.setItem(key, valueToSet);
    }

    public remove(key: SESSION_STORAGE_KEY): void {
        sessionStorage.removeItem(key);
    }

    public clear(): void {
        sessionStorage.clear();
    }
}
