import { Injectable } from '@angular/core';
import { ROUTE_ID } from '../../constants/api-routes';
import { UserRole } from '../../core/models/enums/userRole';
import { HttpService } from '../../core/services/http.service';
import { IListableAdvisor } from '../models/IListableAdvisor';
import { IGetAllAdvisorsFilter } from '../models/IGetAllAdvisorsFilter';
import { IListablePagedResult } from '../models/IListablePagedResult';

@Injectable({
    providedIn: 'root'
})
export class AdvisorService {
    constructor(private readonly httpSvc: HttpService) {}

    public async getAdvisors(projectId: number): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS, { projectId });
    }

    public async getAllAdvisors(queryParams: IGetAllAdvisorsFilter = { skip: 0, take: 20 }, isCancellable = true): Promise<IListablePagedResult<IListableAdvisor>> {
        return await this.httpSvc.getWithQuery<IListablePagedResult<IListableAdvisor>>(ROUTE_ID.ALL_ADVISORS, queryParams, null, null, null, isCancellable ? {} : undefined);
    }

    public async getSupporters(): Promise<Array<IListableAdvisor>> {
        return await this.getAdvisorsByRole(UserRole.Supporter);
    }

    public async getAdvisorsByRole(userRole: UserRole): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS_BY_ROLE, {
            userRole
        });
    }

    public async getAdvisorsForTrainee(): Promise<Array<IListableAdvisor>> {
        return await this.httpSvc.get<Array<IListableAdvisor>>(null, ROUTE_ID.ADVISORS_FOR_TRAINEE);
    }
}