import { Injectable } from '@angular/core';

import { environment as ENV } from '../../../environments/environment';
import { ROUTE_ID, ROUTES } from '../../constants/api-routes';

@Injectable({
    providedIn: 'root'
})
export class RoutesService {
    public static getRouteById(routeId: ROUTE_ID, payload?: object, params?: object): string {
        let routePath = ROUTES[routeId];
        if (!routePath) {
            throw new Error(`Attempt to hit an invalid route: ${ routeId }`);
        }
        if (payload) {
            routePath = this.replaceParams(ROUTES[routeId], payload);
            if (routePath.indexOf('{') > -1) {
                if (params) {
                    routePath = this.replaceParams(ROUTES[routeId], params);
                }

                if (routePath.indexOf('{') > -1) {
                    throw new Error(`Attempt to hit an incomplete route: ${ routeId } > ${ routePath }`);
                }
            }
        }

        return `${ ENV.apiUrl }${ routePath }`;
    }

    public static replaceParams(route: string, params: object): string {
        const keysToRemove = [];

        Object.keys(params)
            .map(key => {
                const param = `{${ key }}`;
                const originalRoute = route;
                route = route.replace(param, params[key]);

                if (route !== originalRoute) {
                    keysToRemove.push(key);
                }
            });

        for (const keyToRemove of keysToRemove) {
            delete params[keyToRemove];
        }

        keysToRemove.splice(0);

        return route;
    }

    public doesUrlMatchRoute(url: string, routeId: string): boolean {
        const routeUrlSegments = ROUTES[routeId]?.split('/');
        const urlSegments = url?.split('/');

        if (urlSegments?.length > 0 && routeUrlSegments?.length > 0 && urlSegments.length >= routeUrlSegments.length) {
            if (routeUrlSegments[0] === '') {
                routeUrlSegments.splice(0, 1);
            }
            urlSegments.splice(0, urlSegments.length - routeUrlSegments.length);
        } else {
            return false;
        }

        for (let i = 0; i < routeUrlSegments.length; i++) {
            const routeSegment = routeUrlSegments[i];
            if (routeSegment !== urlSegments[i] && (routeSegment?.length < 3 || routeSegment[0] !== '{' || routeSegment[routeSegment.length - 1] !== '}')) {
                return false;
            }
        }

        return true;
    }
}