import { Pipe, PipeTransform } from '@angular/core';
import { PrivateTextRegex } from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';

@Pipe({
    name: 'privateText'
})
export class PrivateTextPipe implements PipeTransform {
    constructor(private readonly madTranslateSvc: MadTranslateService) {}

    public transform(value: string, privateMode: boolean): string {
        if (!value) return value;

        const lender = this.madTranslateSvc.getTranslatedLender();

        if (privateMode) {
            return value.replace(PrivateTextRegex, lender);
        } else {
            return value.replace(PrivateTextRegex, (_, privateText) => privateText);
        }
    }
}
