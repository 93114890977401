<div class="app-wrapper" *ngIf="currentUser$ | async">
    <sfx-sticky-bar *ngIf="isBackofficeUser && displayAdminBanner || news?.length">
        <div *ngIf="isBackofficeUser && displayAdminBanner" class="admin-message" (click)="onAdminBannerClicked()">{{ 'auth.backoffice_user_info' | translate }}</div>
        <app-news-bar *ngIf="news?.length" [news]="news"></app-news-bar>
    </sfx-sticky-bar>
    <div class="menu-wrapper">
        <app-sidebar-navigation [urlProjectId]="projectId"></app-sidebar-navigation>
    </div>
    <div class="main-content-wrapper">
        <router-outlet (activate)="subscribeToRouterOutletActivatedRouteParams()"></router-outlet>
    </div>
</div>

<app-session-status-notification></app-session-status-notification>

<router-outlet name="sc"></router-outlet>
<router-outlet name="cfg"></router-outlet>
<router-outlet name="n"></router-outlet>