import { Injectable } from '@angular/core';
import { JsonUtils } from '../utils/json.utils';
import { ObjectUtils } from '../utils/object.utils';

export enum LOCAL_STORAGE_KEY {
    LANGUAGE = 'LANGUAGE',
    SSO_ACTIVE_ID = 'SSO_ACTIVE_ID',
    IS_ANONYMIZED = 'IS_ANONYMIZED',
    LAST_READ_NEWS = 'LAST_READ_NEWS',
    DIAGNOSTIC_LOGS = 'DIAGNOSTIC_LOGS',
    DIAGNOSTIC_LOGS_ENABLED_DATE = 'DIAGNOSTIC_LOGS_ENABLED_DATE',
}

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    public get<T>(key: LOCAL_STORAGE_KEY): T {
        const item = localStorage.getItem(key);
        return JsonUtils.parseText(item) as T;
    }

    public set(key: LOCAL_STORAGE_KEY, content: unknown): void {
        let valueToSet;
        if (ObjectUtils.isObjectOrArray(content)) {
            valueToSet = JSON.stringify(content);
        } else {
            valueToSet = content;
        }

        localStorage.setItem(key, valueToSet);
    }

    public remove(key: LOCAL_STORAGE_KEY): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
