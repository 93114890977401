import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Params, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, Subscription } from 'rxjs';
import { tryToUnsubscribeFromSubscription } from 'sfx-commons';
import { Userpilot } from 'userpilot';
import { environment } from '../../environments/environment';
import { IApplicationUser } from '../core/models/IApplicationUser';
import { NationalityService } from '../core/services/nationality.service';
import { RealTimeNotificationService } from '../core/services/real-time-notification.service';
import { SESSION_STORAGE_KEY, SessionStorageService } from '../core/services/session-storage.service';
import { INews } from '../projects/models/INews';
import { AuthenticationService } from '../projects/services/authentication.service';
import { AuthorizationService } from '../projects/services/authorization.service';
import { NewsService } from '../projects/services/news.service';

@UntilDestroy({ checkProperties: true })
@Component({
    templateUrl: './authorized-root.component.html',
    styleUrl: './authorized-root.component.scss'
})
export class AuthorizedRootComponent implements OnInit, AfterViewInit, OnDestroy {
    public readonly currentUser$: Observable<IApplicationUser>;
    public news: Array<INews> = [];
    public displayAdminBanner = true;
    public isBackofficeUser = false;
    public projectId: number;

    private isUserPilotInitialized = false;
    private routerOutletActivatedRouteParamsSubscription: Subscription;
    private _deferredOperationsTimeout: ReturnType<typeof setTimeout>;

    @ViewChild(RouterOutlet)
    public routerOutlet: RouterOutlet;

    constructor(private readonly authenticationSvc: AuthenticationService,
                private readonly authorizationSvc: AuthorizationService,
                private readonly nationalitySvc: NationalityService,
                private readonly newsSvc: NewsService,
                private readonly nzNotificationSvc: NzNotificationService,
                private readonly rtNotificationSvc: RealTimeNotificationService,
                private readonly sessionStorageSvc: SessionStorageService,
                private readonly translate: TranslateService) {
        this.currentUser$ = this.authenticationSvc.currentUser$;
    }

    public async ngOnInit(): Promise<void> {
        this.displayAdminBanner = !this.sessionStorageSvc.get(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN);
        this.isBackofficeUser = this.authorizationSvc.isSfxAdmin;
        await this.processLoggedInImplications();
    }

    public ngAfterViewInit(): void {
        this.subscribeToRouterOutletActivatedRouteParams();
    }

    public async ngOnDestroy(): Promise<void> {
        clearTimeout(this._deferredOperationsTimeout);
        await this.rtNotificationSvc.disconnect();
    }

    public onAdminBannerClicked(): void {
        this.displayAdminBanner = false;
        this.sessionStorageSvc.set(SESSION_STORAGE_KEY.ADMIN_BANNER_HIDDEN, true);
    }

    public subscribeToRouterOutletActivatedRouteParams(): void {
        tryToUnsubscribeFromSubscription(this.routerOutletActivatedRouteParamsSubscription);
        const routerOutletActivatedRoute = this.routerOutlet?.activatedRoute;
        if (routerOutletActivatedRoute) {
            this.routerOutletActivatedRouteParamsSubscription = routerOutletActivatedRoute.params
                .pipe(untilDestroyed(this))
                .subscribe((params: Params) => this.projectId = params?.id ? +params.id : null);
        }
    }

    private set deferredOperationsTimeout(delegate: () => ReturnType<typeof setTimeout>) {
        clearTimeout(this._deferredOperationsTimeout);
        this._deferredOperationsTimeout = delegate();
    }

    private async processLoggedInImplications(): Promise<void> {
        await this.rtNotificationSvc.connect();
        this.rtNotificationSvc.messageNotifications
            .pipe(untilDestroyed(this))
            .subscribe(messageNotification => {
                // TODO: remove when not necessary for illustrating the functionality
                const { projectId, channel, message, sender, dateTime } = messageNotification.payload;
                const title = `${ projectId } - ${ channel }`;
                const body = `${ sender }: ${ message } @ ${ dateTime }`;
                this.nzNotificationSvc.create(
                    'info',
                    title,
                    body
                );
            });

        this.initializeUserPilot();
        this.deferredOperationsTimeout = () => setTimeout(async () => {
            await this.getNews();
            await this.getNationalities();
        }, 5000);
    }

    private async getNationalities(): Promise<void> {
        try {
            await this.nationalitySvc.getNationalities();
        } catch (e) {
            console.error(e);
        }
    }

    private async getNews(): Promise<void> {
        try {
            this.news = await this.newsSvc.getNews();
        } catch (e) {
            console.error(e);
        }
    }

    private initializeUserPilot(): void {
        if (!!environment.features.userPilot.isEnabled && !this.isUserPilotInitialized) {
            const currentUser = this.authenticationSvc.getCurrentCachedUser();
            const language = this.translate.currentLang;
            Userpilot.initialize(environment.features.userPilot.key);
            Userpilot.identify(currentUser.mail, {
                name: `${ currentUser.firstname } ${ currentUser.lastname }`,
                email: currentUser.mail,
                locale_code: language
            });
            this.isUserPilotInitialized = true;
        }
    }
}
