<sfx-modal [isVisible]="true"
           [title]="modalTitle"
           wrapClassName="settings-modal-wrapper"
           extraModalClasses="settings-modal"
           (cancelClicked)="closeModal()"
>
    <ng-template #modalTitle>
        <sfx-title-bar [icon]="gearIcon"
                       [isModalTitleBar]="true"
                       [isDataInputInModal]="true"
                       titleKey="settings.title"
                       [hasCloseButton]="true"
                       (closeClicked)="closeModal()"
        ></sfx-title-bar>
    </ng-template>
    <div class="modal-content settings-modal-content">
        <div class="modal-content-column">
            <app-language-picker [hasSidebarLayout]="true"
                                 (errorReceived)="handleErrorImplications($event)"
            ></app-language-picker>
            <ng-container *ngIf="currentUserFormGroup">
                <div *ngIf="currentUserFormGroup.controls.csContactPerson.enabled" class="modal-content-row">
                    <sfx-field label="settings.credit_suisse_contact_person"
                               layout="horizontal">
                        <sfx-input [control]="currentUserFormGroup.controls.csContactPerson"
                                   [key]="'currentUser.csContactPerson'"
                        ></sfx-input>
                    </sfx-field>
                </div>
                <div *ngIf="currentUserFormGroup.controls.wkbContactPerson.enabled" class="modal-content-row">
                    <sfx-field label="settings.wkb_contact_person"
                               layout="horizontal">
                        <sfx-input [control]="currentUserFormGroup.controls.wkbContactPerson"
                                   [key]="'currentUser.wkbContactPerson'"
                        ></sfx-input>
                    </sfx-field>
                </div>
                <div *ngIf="currentUserFormGroup.controls.assignedSupporters.enabled" class="modal-content-row">
                    <sfx-field label="global.supporters" layout="horizontal" [labelSuffixTemplate]="isUserPilotSupporterInfoFlowEnabled ? supporterInfoTpl : null">
                        <sfx-loader *ngIf="isLoadingSupporters; else supportersMultiSelectTpl"
                                    [translationKey]="undefined">
                        </sfx-loader>
                        <ng-template #supporterInfoTpl>
                            <sfx-button type="icon" iconButtonSize="s" dress="primary" [icon]="faCircleInfo"
                                        (clicked)="onSupporterInfoIconClicked()"
                                        class="mad-margin-left-4 modal-content-row__supporter-info-icon">
                            </sfx-button>
                        </ng-template>
                        <ng-template #supportersMultiSelectTpl>
                            <sfx-multi-select class="supporters-multi-select" [model]="assignedSupporterIdsModel"
                                              [options]="supporterOptions"
                                              (selectionChanged)="onSupportersMultiSelectChange($event)">
                            </sfx-multi-select>
                        </ng-template>
                    </sfx-field>
                </div>
                <ng-container *ngIf="currentUserFormGroup.controls.useExternalDocumentManagement.enabled">
                    <sfx-section-separator class="modal-content-row-separator"></sfx-section-separator>
                    <div class="modal-content-column no-margin">
                        <div class="modal-content-row">
                            <sfx-field label="settings.document_management"
                                       extraClasses="modal-content-row__title-field"
                                       [labelSuffixTemplate]="betaLabelTpl">
                                    <ng-template #betaLabelTpl>
                                        <sfx-label class="modal-content-row__beta-label mad-padding-left-8" label="BETA"></sfx-label>
                                    </ng-template>
                            </sfx-field>
                        </div>
                        <div class="modal-content-row no-margin-top mad-margin-bottom-24">
                            <sfx-info type="info"
                                      size="small"
                                      [showReadMore]="false"
                                      text="settings.document_management_info"
                            ></sfx-info>
                        </div>
                        <div class="modal-content-row">
                            <sfx-field label="settings.document_management_enable"
                                       layout="horizontal"
                                       type="switch">
                                <sfx-switch [control]="currentUserFormGroup.controls.useExternalDocumentManagement">
                                </sfx-switch>
                            </sfx-field>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
</sfx-modal>
