import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProjectUtils } from '../../core/utils/project.utils';
import { ProjectStatus } from '../../projects/models/enums/projectStatus';
import { DocumentsService } from '../../projects/services/documents.service';
import { ProjectsService } from '../../projects/services/projects.service';

@Directive({ selector: '[appConfirmLeaveBeforeClick]' })
export class ConfirmLeaveBeforeClickDirective {
    @Input()
    public isControlDisabled = false;
    @Input()
    public projectStatus: ProjectStatus;
    @Input()
    public isExternalDocumentManagementEnabled: boolean;
    @Output()
    public afterConfirmed: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    constructor(private readonly documentsSvc: DocumentsService,
                private readonly modalSvc: NzModalService,
                private readonly projectSvc: ProjectsService,
                private readonly translate: TranslateService) {}

    @HostListener('click', ['$event'])
    public async onClick($event: MouseEvent): Promise<void> {
        if (this.isControlDisabled) {
            return;
        }

        const shouldProceed = await ProjectUtils
            .confirmIfShouldProceedWithNavigatingAway(this.projectStatus, this.isExternalDocumentManagementEnabled, this.documentsSvc, this.projectSvc, this.modalSvc, this.translate);
        if (shouldProceed) {
            this.afterConfirmed.emit($event);
        }
    }
}
