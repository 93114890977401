import { environment } from '../../environments/environment';
import { LenderName } from '../projects/models/enums/lenderName';

export const LENDER_ID_NAME_MAP = {
    [environment.lenderIds.ACREVIS]: LenderName.Acrevis,
    [environment.lenderIds.ASGA]: LenderName.ASGA,
    [environment.lenderIds.BANK_CLER]: LenderName.BankCler,
    [environment.lenderIds.BANK_FRICK]: LenderName.BankFrick,
    [environment.lenderIds.BANK_THALWIL]: LenderName.BankThalwil,
    [environment.lenderIds.BDL]: LenderName.BDL,
    [environment.lenderIds.BEKB]: LenderName.BEKB,
    [environment.lenderIds.BPS]: LenderName.BPS,
    [environment.lenderIds.CREDIT_SUISSE_DE]: LenderName.CreditSuisse,
    [environment.lenderIds.CREDIT_SUISSE_FR]: LenderName.CreditSuisse,
    [environment.lenderIds.CREDIT_SUISSE_IT]: LenderName.CreditSuisse,
    [environment.lenderIds.DEMO]: LenderName.Demo,
    [environment.lenderIds.E_HYPO]: LenderName.EHypo,
    [environment.lenderIds.GKB]: LenderName.GKB,
    [environment.lenderIds.GLKB]: LenderName.GLKB,
    [environment.lenderIds.LUKB]: LenderName.LUKB,
    [environment.lenderIds.MIGROS_BANK]: LenderName.MigrosBank,
    [environment.lenderIds.PAX]: LenderName.PAX,
    [environment.lenderIds.PSW]: LenderName.PSW,
    [environment.lenderIds.RAIFFEISEN_BELALP_SIMPLON]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_BENKEN]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_DU_HAUT_LEMAN]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_DU_MONT_TENDRE]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_FRICK_METTAUERTAL]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_GENEVE_RHONE]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_GLARNERLAND]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_GRAUHOLZ]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_LOCARNO]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_LOSONE_PEDEMONTE_VALLEMAGGIA]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_LUZERN]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_MARTIGNY]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_MISCHABEL_MATTERHORN]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_MITTELRHEINTAL]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_NYON_LA_VALLEE]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_RAPPERSWIL_JONA]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_SANKT_GALLEN]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_SION]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_SURBTAL_WEHNTAL]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_SIERRE_REGION]: LenderName.Raiffeisen,
    [environment.lenderIds.RAIFFEISEN_WINTERTHUR]: LenderName.Raiffeisen,
    [environment.lenderIds.SOBA]: LenderName.SoBa,
    [environment.lenderIds.SUVA]: LenderName.Suva,
    [environment.lenderIds.SWISS_LIFE]: LenderName.SwissLife,
    [environment.lenderIds.SWISSCANTO_ANLAGESTIFTUNG]: LenderName.SwisscantoAnlagestiftung,
    [environment.lenderIds.UBS]: LenderName.UBS,
    [environment.lenderIds.UBS_KEY4]: LenderName.UBSKey4,
    [environment.lenderIds.WIR_BANK]: LenderName.WIRBank,
    [environment.lenderIds.WKB]: LenderName.WKB,
    [environment.lenderIds.ZKB]: LenderName.ZKB
};
