import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as DateFns from 'date-fns';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { firstValueFrom } from 'rxjs';
import { SfxCommonsLocalizerService } from 'sfx-commons';
import {
    DateFnsLocales,
    EXTENDED_SUPPORTED_LANGUAGES,
    I18N_LANGUAGE_FILE_NAME,
    LANGUAGE_FILE_NAME_SUFFIX,
    SUPPORTED_LANGUAGE
} from '../../constants';
import { IApplicationUser } from '../../core/models/IApplicationUser';
import { LOCAL_STORAGE_KEY, LocalStorageService } from '../../core/services/local-storage.service';
import { ObjectUtils } from '../../core/utils/object.utils';
import { ISupportedLanguagePack } from '../models/i-supported-language-pack';
import { LocaleUtils } from '../utils/locale.utils';

@Injectable({
    providedIn: 'root'
})
export class MadTranslateService {
    private supportedLanguagePack: ISupportedLanguagePack;
    private initialPathname: string;
    private translatedLender: string;
    constructor(private readonly i18n: NzI18nService,
                private readonly translate: TranslateService,
                private readonly sfxCommonsLocalizerService: SfxCommonsLocalizerService,
                private readonly storageSvc: LocalStorageService) {
        this.initialPathname = window.location.pathname.toLowerCase();
    }

    public getAppropriateUiLanguage(user: IApplicationUser): SUPPORTED_LANGUAGE {
        let uiLanguageValue = SUPPORTED_LANGUAGE.EN;
        const storageLanguageValue = this.storageSvc.get(LOCAL_STORAGE_KEY.LANGUAGE);
        if (storageLanguageValue) {
            uiLanguageValue = ObjectUtils.getKeyForValue(LANGUAGE_FILE_NAME_SUFFIX, storageLanguageValue);
        }
        if (user && user.uiLanguage) {
            uiLanguageValue = SUPPORTED_LANGUAGE[user.uiLanguage];
        }

        return uiLanguageValue;
    }

    public getSelectedLanguageFileSuffix(): string {
        return this.translate.currentLang;
    }

    public getSelectedLanguage(): SUPPORTED_LANGUAGE {
        return ObjectUtils.getKeyForValue(LANGUAGE_FILE_NAME_SUFFIX,
            this.getSelectedLanguageFileSuffix());
    }

    public getSupportedLanguagePack(): ISupportedLanguagePack {
        return this.supportedLanguagePack;
    }

    public getTranslatedLender(): string {
        return this.translatedLender;
    }

    public async initialize(): Promise<void> {
        this.supportedLanguagePack = LocaleUtils.getSupportedLanguages();

        let selectedLanguage;
        const validStorageLanguage = this.parseStorageLanguage();
        if (validStorageLanguage) {
            selectedLanguage = validStorageLanguage;
        } else {
            selectedLanguage = SUPPORTED_LANGUAGE.EN;
        }

        let urlLanguage = null;
        const baseLangIndex = Object.keys(LANGUAGE_FILE_NAME_SUFFIX).findIndex(lang =>
            this.initialPathname.includes(LANGUAGE_FILE_NAME_SUFFIX[lang]));
        const extendedLangKey = Object.keys(EXTENDED_SUPPORTED_LANGUAGES).find(lang =>
            this.initialPathname.includes(lang.toLowerCase()));
        if (baseLangIndex > -1 && !this.initialPathname.includes('en-us')) {
            urlLanguage = this.parseUrlLanguage();
            await this.setSelectedLanguage(urlLanguage || selectedLanguage, false);
        } else if (extendedLangKey) {
            const language = EXTENDED_SUPPORTED_LANGUAGES[extendedLangKey];
            this.storageSvc.set(LOCAL_STORAGE_KEY.LANGUAGE, language);

            let newUrl = this.initialPathname.replace(extendedLangKey.toLowerCase(), language);
            const query = window.location.search;

            await firstValueFrom(this.translate.use(language));
            newUrl = newUrl.replace('projects', this.translate.instant('routes.projects'));

            this.replaceWindowLocation(window.location.origin + newUrl + query);
        } else {
            await this.setSelectedLanguage(selectedLanguage, false);
        }
    }

    public parseUrlLanguage(): string | null {
        const subPaths = this.initialPathname.split('/');
        let language = LANGUAGE_FILE_NAME_SUFFIX[SUPPORTED_LANGUAGE.EN];

        if (subPaths.length > 1) {
            language = subPaths[1];
        }

        const urlLanguage = ObjectUtils.getKeyForValue<string>(LANGUAGE_FILE_NAME_SUFFIX, language);

        if (urlLanguage) {
            if (this.supportedLanguagePack.supportedLanguages[urlLanguage]) {
                return urlLanguage;
            }
        }

        return null;
    }

    public async setSelectedLanguage(language: SUPPORTED_LANGUAGE, doReload = true): Promise<void> {
        const languageFile = LANGUAGE_FILE_NAME_SUFFIX[language];
        if (languageFile === this.translate.currentLang) {
            return;
        }

        this.setNonRouterInvasiveServicesLanguage(language);
        await firstValueFrom(this.translate.use(languageFile));
        if (doReload) {
            window.location.reload();
        }
    }

    public setNonRouterInvasiveServicesLanguage(language: SUPPORTED_LANGUAGE): void {
        this.i18n.setLocale(I18N_LANGUAGE_FILE_NAME[language]);
        this.storageSvc.set(LOCAL_STORAGE_KEY.LANGUAGE, LANGUAGE_FILE_NAME_SUFFIX[language]);
        DateFns.setDefaultOptions({ locale: DateFnsLocales[language] });
        this.sfxCommonsLocalizerService.setCurrentLanguage(language);
        this.translatedLender = this.translate.instant('offers.lender');
    }

    public setSelectedLanguageFileNameSuffix(languageFileNameSuffix: string): void {
        const language = ObjectUtils.getKeyForValue<string>(LANGUAGE_FILE_NAME_SUFFIX, languageFileNameSuffix);
        this.i18n.setLocale(I18N_LANGUAGE_FILE_NAME[language]);
        this.translate.use(languageFileNameSuffix);
        this.sfxCommonsLocalizerService.setCurrentLanguage(language);
    }

    private parseStorageLanguage(): string | null {
        const storageLanguagePrefix = this.storageSvc.get(LOCAL_STORAGE_KEY.LANGUAGE);

        if (storageLanguagePrefix) {
            const storageLanguage = ObjectUtils.getKeyForValue<string>(LANGUAGE_FILE_NAME_SUFFIX, storageLanguagePrefix);

            if (storageLanguage && this.supportedLanguagePack.supportedLanguages[storageLanguage]) {
                return storageLanguage;
            }
        }

        return null;
    }

    private replaceWindowLocation(url: string) {
        window.location.replace(url);
    }
}
