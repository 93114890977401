<div [ngClass]="['mad-navigation-bar-item', navItem.extraClasses || '', navItem.hideCollapsed ? 'hide-collapsed' : '', navItem.isDisabled || isNavigationResolverInProgress ? 'mad-navigation-bar-item--disabled' : '']"
     *ngIf="navItem.isVisible">
    <a *ngIf="navItem.routeSubItems || navItem.outlet; else noRouteItem"
            [routerLink]="[((navItem.routeSubItems || []) | translateRoute: { isAbsolute: navItem.isAbsolute, params: navItem.routeSubItemParams }), navItem.outlet && { outlets: navItem.outlet | translateOutlet: navItem.outletParams }] | noNulls"
            [routerLinkActive]="navItem.routerLinkActive || 'mad--selected mad-elevation-m'"
            [routerLinkActiveOptions]="navItem.routerLinkActiveOptions || { exact: true }"
            [queryParams]="navItem.queryParams"
            [queryParamsHandling]="navItem.queryParamsHandling || ''"
            [attr.disabled]="navItem.isDisabled || isNavigationResolverInProgress ? true : null"
            [ngClass]="{ 'mad--disabled': navItem.isDisabled || isNavigationResolverInProgress,
                         'mad--attention': navItem.highlightType === SidebarItemHighlightType.Error,
                         'mad--warning': navItem.highlightType === SidebarItemHighlightType.Warning,
                         'mad--inverted': navItem.isInverted }"
            routerLinkActive #rla="routerLinkActive"
            class="mad-navigation-button"
            [title]="navItem.key | translate"
            draggable="false"
    >
        <sfx-badge *ngIf="navItem.highlightType === SidebarItemHighlightType.Error; else warningBadgeTemplate"
                   extraIconClasses="mad-icon--xs"
                   popoverText="project.action_required"
                   position="topRight"
        ></sfx-badge>
        <ng-template #warningBadgeTemplate>
            <sfx-badge *ngIf="navItem.highlightType === SidebarItemHighlightType.Warning"
                       [icon]="exclamationIcon"
                       type="warning"
                       extraIconClasses="mad-icon--xs"
                       popoverText="project.pending_documents_warning_message"
                       [position]="isCollapsed ? 'topRight' : 'centerRight'"
            ></sfx-badge>
        </ng-template>
        <ng-container *ngTemplateOutlet="itemContent; context: { navItem: navItem, rla: rla };"></ng-container>
    </a>
    <ng-template #noRouteItem>
        <a [attr.disabled]="navItem.isDisabled || isNavigationResolverInProgress ? true : null"
           (click)="navItem.onClick ? navItem.onClick() : doNothing()"
           class="mad-navigation-button"
           [ngClass]="{ 'mad--selected mad-elevation-m': navItem.isActive, 'mad--disabled': navItem.isDisabled || isNavigationResolverInProgress,
                                 'mad--inverted': navItem.isInverted }"
           [title]="navItem.key | translate"
        >
            <ng-container *ngTemplateOutlet="itemContent; context: { navItem: navItem };"></ng-container>
        </a>
    </ng-template>

    <ng-template #itemContent let-item let-rla>
        <div class="mad-navigation-button__icon">
            <sfx-icon [icon]="isCollapsed && navItem.collapsedIcon ? navItem.collapsedIcon : navItem.icon" size="m"></sfx-icon>
            <div *ngIf="navItem.outstandingNotifications"
                 [class.mad-elevation-xs]="rla?.isActive || navItem.isActive"
                 class="mad-badge mad-middle mad--new mad--pulse">
                <div class="mad-badge__text">{{ navItem.outstandingNotifications }}</div>
            </div>
        </div>
        <div class="mad-tool-tip mad-navigation-button__tool-tip mad--right mad--hidden">
            <div class="mad-tip mad--right" [class.mad-elevation-m]="rla?.isActive || navItem.isActive">
                <div class="mad-tip__body">
                    <div class="mad-tip__text mad-no-wrap">{{ navItem.key | translate }}</div>
                </div>
                <div class="mad-tip__spike"></div>
            </div>
        </div>
        <div class="mad-navigation-button__label mad-margin-left-12">
            {{ navItem.key | translate }}
        </div>
    </ng-template>
</div>
