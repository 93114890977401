export enum ROUTE_ID {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    CURRENT_USER = 'CURRENT_USER',
    MARK_KEY4_TRAINING_AS_COMPLETED = 'MARK_KEY4_TRAINING_AS_COMPLETED',
    OBTAIN_HYPO_DOSSIER_TOKEN = 'OBTAIN_HYPO_DOSSIER_TOKEN',

    LATEST_MODIFIED_PROJECTS = 'LATEST_MODIFIED_PROJECTS',
    CREATE_PROJECT = 'CREATE_PROJECT',
    PROJECT_BY_ID = 'PROJECT_BY_ID',
    LISTABLE_PROJECT_BY_ID = 'LISTABLE_PROJECT_BY_ID',
    CLONE_PROJECT = 'CLONE_PROJECT',
    COPY_PROJECT_DATA = 'COPY_PROJECT_DATA',
    REJECT_PROJECT = 'REJECT_PROJECT',
    PROJECT_STATUSES_BY_ID = 'PROJECT_STATUSES_BY_ID',
    ALL_PROJECT_STATUSES = 'ALL_PROJECT_STATUSES',
    UPDATE_PROJECT_SUBMISSION_DATA = 'UPDATE_PROJECT_SUBMISSION_DATA',
    UNSUPPORTED_PARTNERS_PER_BUSINESS_TYPE = 'UNSUPPORTED_PARTNERS_PER_BUSINESS_TYPE',
    CHANGE_PROJECT_OWNER = 'CHANGE_PROJECT_OWNER',

    ADVISORS = 'ADVISORS',
    ADVISORS_BY_ROLE = 'ADVISORS_BY_ROLE',
    ADVISORS_FOR_TRAINEE = 'ADVISORS_FOR_TRAINEE',
    ALL_ADVISORS = 'ALL_ADVISORS',

    BORROWER_BY_ID = 'BORROWER_BY_ID',
    BORROWER_BY_ID_EXTENDED = 'BORROWER_BY_ID_EXTENDED',
    BORROWER_BY_ID_EXTENDED_CRM = 'BORROWER_BY_ID_EXTENDED_CRM',
    BORROWERS_FOR_PROJECT = 'BORROWERS_FOR_PROJECT',
    BORROWERS_TYPE = 'BORROWERS_TYPE',
    BORROWERS_LEGAL_ENTITIES_BY_ID = 'BORROWERS_LEGAL_ENTITIES_BY_ID',
    BORROWERS_LEGAL_ENTITIES_BY_ID_EXTENDED = 'BORROWERS_LEGAL_ENTITIES_BY_ID_EXTENDED',

    COLLATERALS_FOR_PROJECT = 'COLLATERALS_FOR_PROJECT',
    COLLATERALS_FOR_PROJECT_EXTENDED = 'COLLATERALS_FOR_PROJECT_EXTENDED',
    COLLATERALS_INSTITUTIONS = 'COLLATERALS_INSTITUTIONS',

    FINANCINGS_FOR_PROJECT = 'FINANCINGS_FOR_PROJECT',
    FINANCINGS_FOR_PROJECT_EXTENDED = 'FINANCINGS_FOR_PROJECT_EXTENDED',
    FINANCINGS_FOR_PROJECT_EXTENDED_ASSUMPTION = 'FINANCINGS_FOR_PROJECT_EXTENDED_ASSUMPTION',
    WITHDRAWALS_INSTITUTIONS = 'WITHDRAWALS_INSTITUTIONS',

    FLAT_PROPERTY_BY_ID = 'FLAT_PROPERTY_BY_ID',
    EXTENDED_VALIDATED_FLAT_PROPERTY_BY_ID = 'EXTENDED_VALIDATED_FLAT_PROPERTY_BY_ID',
    HOUSE_PROPERTY_BY_ID = 'HOUSE_PROPERTY_BY_ID',
    EXTENDED_VALIDATED_HOUSE_PROPERTY_BY_ID = 'EXTENDED_VALIDATED_HOUSE_PROPERTY_BY_ID',
    CALCULATE_QUICK_FLAT_VALUATION = 'CALCULATE_QUICK_FLAT_VALUATION',
    CALCULATE_QUICK_HOUSE_VALUATION = 'CALCULATE_QUICK_HOUSE_VALUATION',
    CALCULATE_FLAT_FULL_VALUATION = 'CALCULATE_FLAT_FULL_VALUATION',
    CALCULATE_HOUSE_FULL_VALUATION = 'CALCULATE_HOUSE_FULL_VALUATION',
    UPDATE_SHORT_PROPERTY = 'UPDATE_SHORT_PROPERTY',
    GET_COORDINATES = 'GET_COORDINATES',
    PROPERTY_INVESTMENT_RENTAL_INCOME = 'PROPERTY_INVESTMENT_RENTAL_INCOME',

    OFFERS = 'OFFERS',
    GENERATE_OFFERS = 'GENERATE_OFFERS',
    GROUPED_OFFERS = 'GROUPED_OFFERS',
    CLOSEST_PAYOUT_DATE = 'CLOSEST_PAYOUT_DATE',
    OFFER_TOGGLE_SELECTION = 'OFFER_TOGGLE_SELECTION',
    OFFERS_SELECT = 'OFFERS_SELECT',
    OFFER_REFRESH_INTEREST_RATE = 'OFFER_REFRESH_INTEREST_RATE',
    AVAILABLE_LENDERS = 'AVAILABLE_LENDERS',

    REQUIRED_DOCUMENTS = 'REQUIRED_DOCUMENTS',
    UPLOAD_FILE = 'UPLOAD_FILE',
    UPDATE_FILE = 'UPDATE_FILE',
    DOCUMENT_BY_ID = 'DOCUMENT_BY_ID',
    OFFER_DOCUMENT_BY_ID = 'OFFER_DOCUMENT_BY_ID',
    DOCUMENTS_BY_OFFER_ID = 'DOCUMENTS_BY_OFFER_ID',
    DOCUMENT_PAGE_THUMBNAILS = 'DOCUMENT_PAGE_THUMBNAILS',
    DOCUMENT_GROUP_SET_COMPLETE_STATUS = 'DOCUMENT_GROUP_SET_COMPLETE_STATUS',
    EXTRACT_DOCUMENT = 'EXTRACT_DOCUMENT',
    ASSIGN_DOCUMENT = 'ASSIGN_DOCUMENT',
    OFFER_OVERVIEW_DOCUMENT_GENERATION = 'OFFER_OVERVIEW_DOCUMENT_GENERATION',
    DOSSIER_STATUS = 'DOSSIER_STATUS',
    DOCUMENT_DOWNLOAD_REQUEST_ID = 'DOCUMENT_DOWNLOAD_REQUEST_ID',
    DOCUMENT_DOWNLOAD_REQUEST_STATUS = 'DOCUMENT_DOWNLOAD_REQUEST_STATUS',
    IS_OVER_REQUIRED_DOCUMENTS_THRESHOLD = 'IS_OVER_REQUIRED_DOCUMENTS_THRESHOLD',

    TEAM_SELLING_FOR_PROJECT = 'TEAM_SELLING_FOR_PROJECT',

    GET_LANGUAGES = 'GET_LANGUAGES',

    GET_NATIONALITIES = 'GET_NATIONALITIES',

    RESET_PROJECT_STATUS_DUE_TO_INCOMPLETE_DATA = 'RESET_PROJECT_STATUS_DUE_TO_INCOMPLETE_DATA',
    OFFERS_BIND = 'OFFERS_BIND',
    DOCUMENTS_COMPLETE = 'DOCUMENTS_COMPLETE',
    START_COMPETENCE_CENTER_CHECK = 'START_COMPETENCE_CENTER_CHECK',
    PROJECT_REQUEST_FINANCING_CONFIRMATION = 'PROJECT_REQUEST_FINANCING_CONFIRMATION',
    OFFERS_REQUEST_FIXABLE_OFFER = 'OFFERS_REQUEST_FIXABLE_OFFER',
    OFFERS_FIX_INTEREST_RATE = 'OFFERS_FIX_INTEREST_RATE',
    OFFER_ACCEPT = 'OFFER_ACCEPT',
    OFFERS_CANCEL_ACCEPTED_OFFER = 'OFFERS_CANCEL_ACCEPTED_OFFER',
    SUBMIT_DOCUMENTS_TO_LENDERS = 'SUBMIT_DOCUMENTS_TO_LENDERS',
    SUBMIT_DOCUMENTS_TO_SERVICE_CENTER = 'SUBMIT_DOCUMENTS_TO_SERVICE_CENTER',
    IS_OFFER_BINDING_CANCELLATION_POSSIBLE = 'IS_OFFER_BINDING_CANCELLATION_POSSIBLE',

    LOCATION_AUTOCOMPLETE = 'LOCATION_AUTOCOMPLETE',
    LOCATION_SERVICE_PING = 'LOCATION_SERVICE_PING',

    CRM_GET_CUSTOMER = 'CRM_GET_CUSTOMER',

    GET_NEWS = 'GET_NEWS',
}

export const ROUTES = {
    [ROUTE_ID.LOGIN]: '/Auth/Login',
    [ROUTE_ID.LOGOUT]: '/Auth/Logout',
    [ROUTE_ID.CURRENT_USER]: '/Auth/CurrentUser',
    [ROUTE_ID.MARK_KEY4_TRAINING_AS_COMPLETED]: '/Auth/MarkKey4TrainingAsCompleted',
    [ROUTE_ID.OBTAIN_HYPO_DOSSIER_TOKEN]: '/Auth/ObtainHypoDossierToken',

    [ROUTE_ID.LATEST_MODIFIED_PROJECTS]: '/Project/LastModifiedList',
    [ROUTE_ID.CREATE_PROJECT]: '/Project',
    [ROUTE_ID.PROJECT_BY_ID]: '/Project/{id}',
    [ROUTE_ID.LISTABLE_PROJECT_BY_ID]: '/Project/{id}/WithVersions',
    [ROUTE_ID.CLONE_PROJECT]: '/Project/{id}/Clone',
    [ROUTE_ID.COPY_PROJECT_DATA]: '/Project/{id}/CopyData',
    [ROUTE_ID.REJECT_PROJECT]: '/Project/{id}/Reject',
    [ROUTE_ID.PROJECT_STATUSES_BY_ID]: '/Project/{id}/ProjectStatuses',
    [ROUTE_ID.ALL_PROJECT_STATUSES]: '/Project/AllProjectStatuses',
    [ROUTE_ID.UPDATE_PROJECT_SUBMISSION_DATA]: '/Project/{id}/SubmissionData',
    [ROUTE_ID.UNSUPPORTED_PARTNERS_PER_BUSINESS_TYPE]: '/Project/UnsupportedPartnersPerBusinessType',
    [ROUTE_ID.CHANGE_PROJECT_OWNER]: '/Project/{id}/ChangeProjectOwnerToCurrentUser',

    [ROUTE_ID.ADVISORS]: '/Advisor/GetAdvisorList/{projectId}',
    [ROUTE_ID.ADVISORS_BY_ROLE]: '/Advisor/{userRole}',
    [ROUTE_ID.ADVISORS_FOR_TRAINEE]: '/Advisor/GetAdvisorListForTrainee',
    [ROUTE_ID.ALL_ADVISORS]: '/Advisor',

    [ROUTE_ID.BORROWER_BY_ID]: '/Project/{projectId}/Borrowers/{borrowerId}',
    [ROUTE_ID.BORROWER_BY_ID_EXTENDED]: '/Project/{projectId}/Borrowers/{borrowerId}/Extended',
    [ROUTE_ID.BORROWER_BY_ID_EXTENDED_CRM]: '/Project/{projectId}/Borrowers/{borrowerId}/CrmExtended',
    [ROUTE_ID.BORROWERS_FOR_PROJECT]: '/Project/{projectId}/Borrowers',
    [ROUTE_ID.BORROWERS_TYPE]: '/Project/{projectId}/Borrowers/Type',
    [ROUTE_ID.BORROWERS_LEGAL_ENTITIES_BY_ID]: '/Project/{projectId}/LegalEntities/{legalEntityId}',
    [ROUTE_ID.BORROWERS_LEGAL_ENTITIES_BY_ID_EXTENDED]: '/Project/{projectId}/LegalEntities/{legalEntityId}/Extended',

    [ROUTE_ID.COLLATERALS_FOR_PROJECT]: '/Project/{projectId}/Collaterals',
    [ROUTE_ID.COLLATERALS_FOR_PROJECT_EXTENDED]: '/Project/{projectId}/Collaterals/Extended',
    [ROUTE_ID.COLLATERALS_INSTITUTIONS]: '/Project/{projectId}/Collaterals/Institutions',

    [ROUTE_ID.FINANCINGS_FOR_PROJECT]: '/Project/{projectId}/Financings',
    [ROUTE_ID.FINANCINGS_FOR_PROJECT_EXTENDED]: '/Project/{projectId}/Financings/Extended',
    [ROUTE_ID.FINANCINGS_FOR_PROJECT_EXTENDED_ASSUMPTION]: '/Project/{projectId}/Financings/Extended/Assumption',
    [ROUTE_ID.WITHDRAWALS_INSTITUTIONS]: '/Project/{projectId}/Financings/Withdrawals/Institutions',

    [ROUTE_ID.FLAT_PROPERTY_BY_ID]: '/Project/{projectId}/Flat',
    [ROUTE_ID.EXTENDED_VALIDATED_FLAT_PROPERTY_BY_ID]: '/Project/{projectId}/ExtendedValidatedFlat',
    [ROUTE_ID.HOUSE_PROPERTY_BY_ID]: '/Project/{projectId}/SingleFamilyHouse',
    [ROUTE_ID.EXTENDED_VALIDATED_HOUSE_PROPERTY_BY_ID]: '/Project/{projectId}/ExtendedValidatedSingleFamilyHouse',
    [ROUTE_ID.CALCULATE_QUICK_FLAT_VALUATION]: '/Project/{projectId}/Property/Flat/CalculateQuickValuation',
    [ROUTE_ID.CALCULATE_QUICK_HOUSE_VALUATION]: '/Project/{projectId}/Property/House/CalculateQuickValuation',
    [ROUTE_ID.CALCULATE_FLAT_FULL_VALUATION]: '/Project/{projectId}/Property/Flat/CalculateFullValuation',
    [ROUTE_ID.CALCULATE_HOUSE_FULL_VALUATION]: '/Project/{projectId}/Property/House/CalculateFullValuation',
    [ROUTE_ID.UPDATE_SHORT_PROPERTY]: '/Project/{projectId}/Property',
    [ROUTE_ID.GET_COORDINATES]: '/Property/GetCoordinates',
    [ROUTE_ID.PROPERTY_INVESTMENT_RENTAL_INCOME]: '/Project/{projectId}/InvestmentRentalIncome',

    [ROUTE_ID.OFFERS]: '/Project/{projectId}/Offers',
    [ROUTE_ID.GENERATE_OFFERS]: '/Project/{projectId}/AsyncOffers',
    [ROUTE_ID.GROUPED_OFFERS]: '/Project/{projectId}/Offers/Grouped',
    [ROUTE_ID.CLOSEST_PAYOUT_DATE]: '/Offer/ClosestPayoutDate',
    [ROUTE_ID.OFFER_TOGGLE_SELECTION]: '/Project/{projectId}/Offers/{id}/ToggleSelection',
    [ROUTE_ID.OFFERS_SELECT]: '/Project/{id}/Offers/Select',
    [ROUTE_ID.OFFER_REFRESH_INTEREST_RATE]: '/Project/{projectId}/Offers/{id}/UpdateInterestRate',
    [ROUTE_ID.AVAILABLE_LENDERS]: '/Offer/AvailableLenders',

    [ROUTE_ID.REQUIRED_DOCUMENTS]: '/Project/{projectId}/Documents/RequiredDocuments',
    [ROUTE_ID.UPLOAD_FILE]: '/Project/Documents/Upload',
    [ROUTE_ID.UPDATE_FILE]: '/Project/Documents/Update',
    [ROUTE_ID.DOCUMENT_BY_ID]: '/Project/{projectId}/Documents/{id}',
    [ROUTE_ID.OFFER_DOCUMENT_BY_ID]: '/Project/{projectId}/Documents/OfferDetails/{documentId}',
    [ROUTE_ID.DOCUMENTS_BY_OFFER_ID]: '/Project/{projectId}/Documents/Offer/{id}',
    [ROUTE_ID.DOCUMENT_PAGE_THUMBNAILS]: '/Project/{projectId}/Documents/{id}/PageThumbnails',
    [ROUTE_ID.DOCUMENT_GROUP_SET_COMPLETE_STATUS]: '/Project/{projectId}/Documents/SetGroupCompleteStatus',
    [ROUTE_ID.EXTRACT_DOCUMENT]: '/Project/{projectId}/Documents/Extract',
    [ROUTE_ID.ASSIGN_DOCUMENT]: '/Project/{projectId}/Documents/Groups/Assign',
    [ROUTE_ID.OFFER_OVERVIEW_DOCUMENT_GENERATION]: '/Project/{projectId}/Documents/OfferOverviewDocumentGeneration',
    [ROUTE_ID.DOSSIER_STATUS]: '/Project/{projectId}/Documents/DossierStatus',
    [ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_ID]: '/Project/{projectId}/Documents/ObtainHDGetDocumentDownloadRequestId',
    [ROUTE_ID.DOCUMENT_DOWNLOAD_REQUEST_STATUS]: '/Project/{projectId}/Documents/GetHDDocumentDownloadRequestStatus/{requestId}',
    [ROUTE_ID.IS_OVER_REQUIRED_DOCUMENTS_THRESHOLD]: '/Project/{projectId}/Documents/IsOverRequiredDocumentsThreshold',

    [ROUTE_ID.TEAM_SELLING_FOR_PROJECT]: '/Project/{projectId}/TeamSellingForProject',

    [ROUTE_ID.GET_LANGUAGES]: '/Language',

    [ROUTE_ID.GET_NATIONALITIES]: '/Nationality',

    [ROUTE_ID.RESET_PROJECT_STATUS_DUE_TO_INCOMPLETE_DATA]: '/Project/{id}/ResetProjectStatusDueToIncompleteData',
    [ROUTE_ID.OFFERS_BIND]: '/Project/{id}/BindSelectedOffers',
    [ROUTE_ID.DOCUMENTS_COMPLETE]: '/Project/{id}/MarkDocumentsAsCompleted',
    [ROUTE_ID.START_COMPETENCE_CENTER_CHECK]: '/Project/{id}/StartCompetenceCenterCheck',
    [ROUTE_ID.PROJECT_REQUEST_FINANCING_CONFIRMATION]: '/Project/{id}/RequestFinancingConfirmation',
    [ROUTE_ID.OFFERS_REQUEST_FIXABLE_OFFER]: '/Project/{id}/RequestFixableOffer',
    [ROUTE_ID.OFFERS_FIX_INTEREST_RATE]: '/Project/{id}/FixInterestRate',
    [ROUTE_ID.OFFER_ACCEPT]: '/Project/{projectId}/Offers/{id}/Accept',
    [ROUTE_ID.OFFERS_CANCEL_ACCEPTED_OFFER]: '/Project/{id}/CancelAcceptedOffer',
    [ROUTE_ID.SUBMIT_DOCUMENTS_TO_LENDERS]: '/Project/{id}/SubmitDocumentsToLenders',
    [ROUTE_ID.SUBMIT_DOCUMENTS_TO_SERVICE_CENTER]: '/Project/{id}/SubmitDocumentsToServiceCenter',
    [ROUTE_ID.IS_OFFER_BINDING_CANCELLATION_POSSIBLE]: '/Project/{id}/IsOfferBindingCancellationPossible',

    [ROUTE_ID.LOCATION_AUTOCOMPLETE]: '/Location/Autocomplete/{locationProvider}',
    [ROUTE_ID.LOCATION_SERVICE_PING]: '/Location/Ping',

    [ROUTE_ID.CRM_GET_CUSTOMER]: '/CrmData/Customer/{id}',

    [ROUTE_ID.GET_NEWS]: '/News',
};
