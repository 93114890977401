import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationRef, NzNotificationService } from 'ng-zorro-antd/notification';
import { NzNotificationDataOptions } from 'ng-zorro-antd/notification/typings';

@Injectable({
    providedIn: 'root'
})
export class PopupNotificationService {

    constructor(private readonly notification: NzNotificationService,
                private readonly translate: TranslateService) {}

    public createNotification(type: string, titleKey: string, content: string, fallbackContentKey?: string, fallbackContentKeyInterpolateParams?: unknown, options?: NzNotificationDataOptions): void {
        this.notification.create(
            type,
            this.translate.instant(titleKey),
            content || this.translate.instant(fallbackContentKey, fallbackContentKeyInterpolateParams),
            options
        );
    }

    public createNotificationWithTemplate(notificationTemplate: TemplateRef<unknown>, options?: NzNotificationDataOptions): NzNotificationRef {
        return this.notification.template(notificationTemplate, options);
    }

    public removeNotification(notificationId: string): void {
        this.notification.remove(notificationId);
    }
}
