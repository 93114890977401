import { Pipe, PipeTransform } from '@angular/core';
import { SafeAny } from '../../constants';
import { isNumber } from 'sfx-commons';

@Pipe({
    name: 'address'
})
export class AddressPipe implements PipeTransform {
    public transform(value: SafeAny): SafeAny {
        const address = [];

        if (value) {
            // eslint-disable-next-line no-prototype-builtins
            const city = value.hasOwnProperty('city') ? value.city : value.town;

            if (value.street && value.houseNumber) {
                address.push(value.street + ' ' + value.houseNumber);
            } else if (value.street) {
                address.push(value.street);
            } else if (isNumber(value.houseNumber)) {
                address.push(value.houseNumber);
            }

            if (city && value.zip) {
                address.push(value.zip + ' ' + city);
            } else if (city) {
                address.push(city);
            } else if (value.zip) {
                address.push(value.zip);
            }
        }

        return address.join(', ');
    }
}
