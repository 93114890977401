import { Injectable } from '@angular/core';
import {
    ActivatedRoute, GuardsCheckStart,
    NavigationCancel,
    NavigationEnd,
    NavigationError, NavigationExtras, ResolveStart,
    Router, UrlTree
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, distinctUntilChanged, filter, Observable } from 'rxjs';
import {
    LANGUAGE_FILE_NAME_SUFFIX, LOGIN_QUERY, MAIN_ROUTES,
    OUTLET_NAMES,
    PATH_NAMES_PER_OUTLETS, PROJECT_PAGE_URL_SUBITEMS_TEMPLATE,
    SafeAny
} from '../../constants';
import { MadTranslateService } from '../../mad-translate/services/mad-translate.service';
import { isNullOrUndefined, isNumeric } from 'sfx-commons';
import { ObjectUtils } from '../utils/object.utils';
import { RouterUtils } from '../utils/router.utils';
import { AppInitializerService } from './app-initializer.service';

export type OutletWithSubOutlets = {
    name: OUTLET_NAMES,
    parameters?: Array<number | string>,
    subOutlets?: Array<OutletWithSubOutlets>
};

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class RouterService {
    public readonly isNavigationGuardOrResolverInProgress$: Observable<boolean>;

    private readonly isNavigationGuardOrResolverInProgressSubject: BehaviorSubject<boolean>;
    private readonly ROUTE_PREFIX = 'routes.';

    private translatedLoginRoute: string;
    private translatedLoginBoRoute: string;
    private translatedLoggingInRoute: string;
    private translatedLogoutRoute: string;

    constructor(private readonly router: Router,
                private readonly translate: TranslateService,
                private readonly madTranslateSvc: MadTranslateService) {
        this.isNavigationGuardOrResolverInProgressSubject = new BehaviorSubject<boolean>(false);
        this.isNavigationGuardOrResolverInProgress$ = this.isNavigationGuardOrResolverInProgressSubject.asObservable().pipe(distinctUntilChanged());

        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof GuardsCheckStart || event instanceof ResolveStart || event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError)
            )
            .subscribe(event => {
                this.setIsNavigationGuardOrResolverInProgress(event instanceof GuardsCheckStart || event instanceof ResolveStart);
            });

        this.translate.onLangChange
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.translatedLoginRoute = this.translateRoute([MAIN_ROUTES.LOGIN], null, true);
                this.translatedLoginBoRoute = this.translateRoute([MAIN_ROUTES.LOGIN_BACKOFFICE], null, true);
                this.translatedLoggingInRoute = this.translateRoute([MAIN_ROUTES.LOGGING_IN], null, true);
                this.translatedLogoutRoute = this.translateRoute([MAIN_ROUTES.LOGOUT], null, true);
            });
    }

    public get currentUrl(): string {
        return this.router.url;
    }

    public setIsNavigationGuardOrResolverInProgressToFalse(): void {
        this.setIsNavigationGuardOrResolverInProgress(false);
    }

    public async closeOutlet(outlet: OUTLET_NAMES,
                             extras?: NavigationExtras) {
        const { outletName } = this.translateOutlet(outlet);
        await this.router.navigate([{ outlets: { [outletName]: null } }], extras);
    }

    public async navigateToOutlet(outlet: OUTLET_NAMES,
                                  parameters: Array<number>,
                                  relativeTo: ActivatedRoute | null,
                                  replaceUrl?: boolean): Promise<boolean> {
        const { outletName, pathName } = this.translateOutlet(outlet);
        let routeNamesParams: Array<string | number> = [pathName];
        if (parameters?.length) {
            routeNamesParams = [pathName, ...parameters];
        }
        return await this.router.navigate([{ outlets: {
                [outletName]: routeNamesParams
            } }], { relativeTo, replaceUrl });
    }

    public async navigateToOutlets(outletsWithParams: Array<OutletWithSubOutlets>,
                                   replaceUrl?: boolean): Promise<boolean> {
        return await Promise.sequentialEach<boolean>(outletsWithParams, async (outlet, i) => {
            const { outletName, pathName } = this.translateOutlet(outlet.name);
            let routeNamesParams: Array<string | number | SafeAny> = [pathName];
            if (outlet.parameters?.length) {
                routeNamesParams = [pathName, ...outlet.parameters];
            }
            const relativeTo = this.getCurrentActivatedRoute();

            const result = await this.router
                .navigate([{ outlets: { [outletName]: routeNamesParams } }], { relativeTo, replaceUrl });

            if (i === outletsWithParams.length - 1) {
                return result;
            }
        });
    }

    public async navigateToRoute(routeSubItems: string | Array<string | number>,
                                 parameters?: Array<number>,
                                 extras?: NavigationExtras,
                                 isAbsoluteRoute = true): Promise<boolean> {
        let url = '';
        if (routeSubItems instanceof Array) {
            const subItems = [...routeSubItems];
            url = this.translateRoute(subItems, parameters, isAbsoluteRoute);
        } else {
            url = routeSubItems;
        }
        return await this.router.navigate([url], extras);
    }

    public async navigateToRelativeSubRouteWithParam(routeSubItems: string | Array<string | number>, currentStep: number, newStep: number): Promise<boolean> {
        let localizedRoute = '';
        if (routeSubItems instanceof Array) {
            const subItems = [...routeSubItems];
            localizedRoute = this.translateRoute(subItems);
        } else {
            localizedRoute = this.translateRoute([routeSubItems]);
        }

        let oldRoute = `${localizedRoute}`;
        if (!isNullOrUndefined(currentStep)) {
            oldRoute += `/${currentStep}`;
        }

        let newRoute = `${localizedRoute}`;
        if (!isNullOrUndefined(newStep)) {
            newRoute += `/${newStep}`;
        }

        return await this.router.navigateByUrl(this.router.url.replace(oldRoute, newRoute));
    }

    public translateOutlet(outletName: OUTLET_NAMES): { outletName: OUTLET_NAMES, pathName: string } {
        let pathName = this.translate.instant(`${this.ROUTE_PREFIX}${PATH_NAMES_PER_OUTLETS[outletName]}`);
        pathName = this.useFallbackTranslationForRoute(pathName);

        return {
            outletName,
            pathName
        };
    }

    public translateRoute(routeSubItems: Array<string | number>, parameters?: Array<string | number>, isAbsoluteRoute = false): string {
        let route = '';
        let paramIndex = 0;
        for (let index = 0; index < routeSubItems?.length; index++) {
            let subRoute = routeSubItems[index].toString();
            if (subRoute === '/') {
                subRoute = '';
            } else if (!isNumeric(subRoute)) {
                if ((subRoute === '{projectId}' || // legacy
                    subRoute.startsWith('{') && subRoute.endsWith('}')) &&
                    parameters?.length && paramIndex < parameters.length) {
                    subRoute = parameters[paramIndex].toString();
                    paramIndex++;
                } else {
                    const item = subRoute + '';
                    if (item.startsWith(this.ROUTE_PREFIX)) {
                        subRoute = this.translate.instant(item) as string;
                    } else {
                        subRoute = this.translate.instant(`${ this.ROUTE_PREFIX }${ item }`) as string;
                    }
                    subRoute = this.useFallbackTranslationForRoute(subRoute);
                }
            }
            if (index > 0) {
                route += '/';
            }
            route += subRoute;
        }

        if (isAbsoluteRoute) {
            route = `/${ this.getCurrentLanguage() }/${ route }`;
        }

        return route;
    }

    public async navigateToLogout(extras?: NavigationExtras): Promise<void> {
        const returnUrl = extras?.queryParams?.[LOGIN_QUERY.RETURN_URL];
        if (!!returnUrl) {
            extras.queryParams[LOGIN_QUERY.RETURN_URL] = this.getValidReturnUrl(returnUrl);
        }

        await this.navigateToRoute([MAIN_ROUTES.LOGOUT], null, extras);
    }

    public isUrlInProjectContext(url: string, projectId: number): boolean {
        const translatedProjectUrl = this.translateRoute(PROJECT_PAGE_URL_SUBITEMS_TEMPLATE, [projectId], true);
        return url.startsWith(translatedProjectUrl);
    }

    private getCurrentActivatedRoute(): ActivatedRoute {
        let route = this.router.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }

        return route;
    }

    private getCurrentLanguage(): string {
        return this.madTranslateSvc.getSelectedLanguageFileSuffix() || RouterUtils.getLanguageFromUri();
    }

    public getValidReturnUrl(returnUrl: string, excludedQueryParams: Array<string> = []): string {
        let validReturnUrl: UrlTree = null;
        const isValid = this.isValidReturnUrl(returnUrl);
        if (isValid) {
            validReturnUrl = this.router.parseUrl(returnUrl);
            for (const queryParam of excludedQueryParams) {
                delete validReturnUrl.queryParams[queryParam];
            }
        }
        return validReturnUrl ? validReturnUrl.toString() : null;
    }

    public isValidReturnUrl(returnUrl: string): boolean {
        return returnUrl &&
            returnUrl.length > 1 &&
            !returnUrl.startsWith(this.translatedLoginRoute) &&
            !returnUrl.startsWith(this.translatedLoginBoRoute) &&
            !returnUrl.startsWith(this.translatedLoggingInRoute) &&
            !returnUrl.startsWith(this.translatedLogoutRoute);
    }

    private removeNonTranslatedRoutePrefix(subRoute: string): string {
        if (subRoute.startsWith(this.ROUTE_PREFIX)) {
            subRoute = subRoute.substring(this.ROUTE_PREFIX.length);
        }

        return subRoute;
    }

    private setIsNavigationGuardOrResolverInProgress(value: boolean): void {
        this.isNavigationGuardOrResolverInProgressSubject.next(value);
    }

    private useFallbackTranslationForRoute(subRoute: string): string {
        if (subRoute.startsWith(this.ROUTE_PREFIX)) {
            const currentLanguage = this.getCurrentLanguage();
            const currentLanguageKey = ObjectUtils.getKeyForValue<string>(LANGUAGE_FILE_NAME_SUFFIX, currentLanguage);
            subRoute = this.removeNonTranslatedRoutePrefix(subRoute);
            const localTranslatedRoute = AppInitializerService.asyncRoutes[currentLanguageKey][subRoute] as string;
            if (localTranslatedRoute) {
                subRoute = localTranslatedRoute;
            }
        }

        return subRoute;
    }
}
