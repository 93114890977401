import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IOfferList } from '../../projects/models/IOfferList';
import { OfferService } from '../../projects/services/offer.service';
import { faPercent, faScaleUnbalanced } from '@fortawesome/pro-solid-svg-icons';

@UntilDestroy()
@Component({
    selector: 'app-ltv-affordability-summary',
    templateUrl: './ltv-affordability-summary.component.html',
    styleUrls: ['./ltv-affordability-summary.component.scss']
})
export class LtvAffordabilitySummaryComponent implements OnInit {
    @Input({ required: true })
    public projectId: number;

    public offerList: IOfferList;

    public readonly percentIcon = faPercent;
    public readonly scaleUnbalancedIcon = faScaleUnbalanced;

    constructor(private readonly offerService: OfferService) {}

    public async ngOnInit(): Promise<void> {
        this.offerService.cachedOffersObservable
            .pipe(untilDestroyed(this))
            .subscribe(async (offers: IOfferList) => {
                if (!!offers && offers.projectId !== this.projectId) {
                    return;
                }
                this.offerList = offers;
            });
    }
}
