import { Component, Input } from '@angular/core';
import { IsActiveMatchOptions, Params, QueryParamsHandling } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faExclamation } from '@fortawesome/pro-solid-svg-icons';
import { SafeAny } from '../../constants';

export enum SidebarItemHighlightType {
    Error = 'Error',
    Warning = 'Warning'
}

export interface ISidebarItem {
    routeSubItems?: Array<string | number>;
    routeSubItemParams?: Array<string | number>;
    routerLinkActive?:  string[] | string;
    routerLinkActiveOptions?: { exact: boolean; } | IsActiveMatchOptions;
    isAbsolute?: boolean;
    key: string;
    icon: IconProp;
    collapsedIcon?: IconProp;
    outstandingNotifications?: number;
    hasSeparatorAfter?: boolean;
    outlet?: { [key: string]: string };
    outletParams?: Array<number>;
    queryParams?: Params | null | undefined;
    queryParamsHandling?: QueryParamsHandling;
    isDisabled?: boolean;
    isVisible: boolean;
    highlightType?: SidebarItemHighlightType;
    extraClasses?: string;
    hideCollapsed?: boolean;
    onClick?: SafeAny;
    isActive?: boolean;
    isInverted?: boolean;
}

@Component({
    selector: 'app-sidebar-item',
    templateUrl: './sidebar-item.component.html',
    styleUrls: ['./sidebar-item.component.scss']
})
export class SidebarItemComponent {
    @Input({ required: true })
    public navItem: ISidebarItem;
    @Input()
    public isCollapsed = false;
    @Input()
    public isNavigationResolverInProgress = false;

    public readonly SidebarItemHighlightType = SidebarItemHighlightType;
    public readonly exclamationIcon = faExclamation;

    public doNothing = () => null;
}
